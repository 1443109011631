/*footer*/
*{
    margin: 0;
    padding: 0;
}
ul li{
    text-decoration: none;
    list-style: none;
}
a{
    text-decoration: none;
    color: #ffffff;
}
a:hover{
    color: #813372;
    transition: 0.5s;
}
 div .page-footer{
    background: #062f4f;
    color: #ffffff;
    height: auto;
 }
 div .page-footer .container{
    width: 100%;
    height: auto;
    margin-top: 0px;
 }
 /* sobre */
 div .page-footer .container .row div li{
    list-style: none;
    text-align: left;
    color: #ffffff;
 }
 
 div .page-footer .container .row div li a{
     color: #ffffff;
 }
 div .page-footer .container .row div li a:hover{
    transition: 0.5s;
    color: #0b0025;
}
/* Forms */
div .page-footer .container .row div form button{
    background: #8b2621;
}
 /*Midias Sociais da Quattro*/
 div .page-footer .socialImg{
    width: 100%;
    float: left;
 }
 div .page-footer .socialImg li{     
    display: inline-block;
 }
 div .page-footer .socialImg li img{   
     width: 50px;
     height: auto;
 }
/* Parceiros da Quattro*/
 div .page-footer .parceirosImg{
    width: 100%;
 }
 div .page-footer .parceirosImg li{     
    display: inline-block;
 }
 div .page-footer .parceirosImg li img{   
     width: 50px;
     height: auto;
 }

 /* Copyright */
 div .page-footer .footer-copyright{
     background: #0b0025;
     height: auto;
 }

@media(min-width: 1200px){
    
} 

@media(max-width: 1200px){
    
}

@media(max-width: 800px){
    div .page-footer .socialImg li img{   
        width: 35px;
        height: auto;
    }
    div .page-footer .parceirosImg li img{   
        width: 35px;
        height: auto;
    }
}

@media(max-width: 480px){
    div .page-footer .socialImg li img{   
        width: 20px;
        height: auto;
    }
    div .page-footer .parceirosImg li img{   
        width: 20px;
        height: auto;
    }
}
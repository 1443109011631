*{
    margin: 0;
    padding: 0;
}
ul li{
    text-decoration: none;
}
a{
    text-decoration: none;
    color: #ffffff;
}
a:hover{
    color: rgb(248, 113, 133);
    transition: 0.5s;
}
#menu nav div{
    background: #062f4f;
}
/* Nav Bar */
nav div div div img{
    width: 58px;
    height: auto;
}
/* Menu */
nav div div ul li{
    font-size: 18pt;
    text-transform: uppercase;
}
nav div div ul li a:hover{
    transition:all 0.5s;
    color: #813372;
}
/* Menu Dropdown */
div #aluno{
    margin-top: 60px;
    background: #062f4f;
}
div #aluno a {
    color: #ffffff;
}
div #aluno a:hover{
    transition:all 0.5s;
    color: #813372;
}
@media(min-width: 1200px) {
   
}
/* SCREEN TABLET */
@media(min-width: 801px) and (max-width: 1199px) {
    
}
/* SCREEN TABLET */
@media(max-width: 800px) {
    
}

@media(max-width: 600px){
   
}

@media(max-width: 405px){
   
}